<template>
<div>
	<TopNav></TopNav>
	<Header></Header>
	<div class="Area1">
		<div class="Wrap">
			
			<div class="Left">
				
				<el-carousel height="450px" trigger="click">
				  <el-carousel-item v-for="(ad,adI) in SwiperList" :key="adI">
					   <div>
						   <img :src="ad.ShowThumb" style="height: 450px;width: 100%;" />
					   </div>
				  </el-carousel-item>
				</el-carousel>
				
			</div>
			
			<div class="Right">
				
				<div class="Login">
					<h4>
						账号登陆
					</h4>
					<li>
						<el-input v-model="Account" placeholder="请输入用户名"></el-input>
					</li>
					<li>
						<el-input v-model="Password" show-password placeholder="请输入密码"></el-input>
					</li>
					<li>
						<span class="PCheckbox"><el-checkbox style="color: rgba(0,0,0,0.7);border-color: rgba(25,135,66,1);" v-model="RememberAccount"><label style="color: rgba(0,0,0,0.7);">记住用户名</label></el-checkbox></span>
						<label class="Forgot" @click="$Jump('/forgot')">忘记密码?</label>
					</li>
					<li style="margin-top: 30px;">
						<el-button style="width: 100%;background-color:rgba(25,135,66,1);color: #FFFFFF;border-color: rgba(25,135,66,1);" @click="Login()">登录</el-button>
					</li>
				</div>
				
			
				
			</div>
			
		</div>
	</div>
	<Footer></Footer>
</div>
</template>

<script>
	import {  Carousel,CarouselItem,Checkbox,Dialog } from 'element-ui';
	import md5 from 'md5';
	export default {
	  name: 'Login',
	  props: {
	  },
	  data() {
	      return {
	        Account: '',
			Password:'',
			RememberAccount:true,
			SwiperList:[
				{
					Id:'1231',
					ShowTitle:'',
					JumpTarget:'',
					ShowThumb:'https://img.wojiasx.com/s/99a18ea7-2a30-48a7-9ca4-c2b4443f7f9c.jpeg',
					BgColor:'#286dff',
					JumpType:'uri',
				}
			]
	      }
	    },
	  components: {
		  "el-carousel":Carousel,
		  "el-carousel-item":CarouselItem,
		  "el-checkbox":Checkbox
	  },
	  created() {
	  	this.Account = localStorage.getItem('RememberAccount')
		this.SwiperList()
	  },
	  methods:{
		  SuccessJump(){
			  this.$Jump('/')
		  },
		  Login(){
		  		let that = this
		  		let data = {
		  			Service:'User',
		  			Class: 'User',
		  			Action: 'Login',
		  			Account:this.Account,
		  			Password:md5(this.Password),
		  		}
				
				if(this.RememberAccount){
					localStorage.setItem('RememberAccount', this.Account)
				}
				
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((response) => {
		  			
		  			if(response.ErrorId != 0){
						this.$message({
						  message: response.Msg,
						  type: 'error'
						});
						
		  				return
		  			}
		  			
		  			this.$message({
		  			  message: response.Msg,
		  			  type: 'success'
		  			});
		  			
					localStorage.setItem('LoginData',JSON.stringify(response.Data))
					
		  			this.$store.dispatch('setToken',response.Data.Token)
		  			this.$store.dispatch('setUserName',response.Data.Account)
		  			this.$store.dispatch('setIdentity',response.Data.Identity)
		  			this.$store.dispatch('setIdentityName',response.Data.IdentityName)
					this.$store.dispatch('setShopId',response.Data.ShopId)
					this.$store.dispatch('setTroopId',response.Data.TroopId)
		  			this.GetCartCount()
					
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  GetCartCount(){
			  
		  		  //发起网络请求，添加购物清单
		  		  let data = {
		  			Service:'Goods',
		  			Class: 'ShoppingCart',
		  			Action: 'Count',
					IsPt: 2,
		  		  }
		  		  
		  		  this.$post(this.$store.getters.getApiHost,data)
		  		  .then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
		  			
		  			if(res.Data.Count > 0){
		  				this.$store.dispatch('setCartItems',res.Data.Count)
		  			}else{
		  				this.$store.dispatch('setCartItems',0)
		  			}
					
			
					this.$Jump('/')
		  			
		  		  })
		  },
		  GetSwiper(){
			  let data = {
			  	Service:'Ad',
			  	Class: 'Ad',
			  	Action: 'List',
			  	Page:1,
			  	PageSize:3,
			  	SKey:'pc_login2_ad'
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){this.$message(res.Msg);return;}
			  	if(res.Data.AdList == undefined || res.Data.AdList == null || res.Data.AdList.length == 0){
			  		return
			  	}
			  	this.SwiperList = res.Data.AdList
			  	
			  })
			  .catch(function (response) {
			  	this.$message('网络请求错误')
			  })
		  }
	  }
	}
</script>

<style scoped>
	
.Area1{
	background-color: rgba(25,135,66,1);
	height: 450px;
	padding: 20px 0px;
}
.Area1 .Left{
	width: 850px;
	height: 450px;
}
.Area1 .Right{
	flex: 1;
	background-color: #FFFFFF;
}
.Login{
	
}
.Login h4{
	font-size: 16px;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}
.Login li{
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
}
.Login .Forgot{
	float: right;
	font-size: 12PX;
	cursor: pointer;
}
</style>
